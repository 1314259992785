/* Pagination wrapper */
.react-bootstrap-table-pagination {
    justify-content: space-between;
    width: 100%;
    padding: 10px;
}

/* Right side of pagination */
.react-bootstrap-table-pagination-list {
    flex: 0 !important;
}